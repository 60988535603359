<script>
import QuizAnswerMediaContent from '@shared/components/QuizAnswerMediaContent.vue';

export default {
  components: { QuizAnswerMediaContent },
  props: {
    trainingItem: {
      type: Object,
      default: null,
    },
    quizResponse: {
      type: Object,
      required: true,
    },
  },
  computed: {
    explanationLabel() {
      return this.$t('explanation');
    },
  },
  i18n: {
    messages: {
      en: {
        explanation: 'Explanation',
      },
      fr: {
        explanation: 'Explication',
      },
    },
  },
};
</script>

<template>
  <div>
    <article
      v-for="(q, k) in quizResponse.quiz_details"
      :key="q.id"
      :class="{'mt-5': k > 0}"
      class="box">
      <h3 class="title is-5 is-flex is-align-items-center">
        {{ q.question }}

      </h3>
      <p v-if="!q.answers.some(a => a.customer_answer)" class="subtitle has-text-danger is-7">
        Vous n'avez pas répondu à cette question.
      </p>
      <div v-for="a in q.answers" :key="a.id" class="mb-3">
        <div
          class="is-flex bradius-4 p-2 mb-0"
          :class="{
            'ctiqcomp_bad': a.customer_answer && !a.is_correct,
            'ctiqcomp_good': a.is_correct,
          }"
        >
          <b-icon
            pack="far"
            size="is-medium"
            :icon="a.customer_answer ? 'check-square' : 'square'"
          />
          <div class="mt-1 ml-3 w-full break-words">
            {{ a.answer }}
            <QuizAnswerMediaContent
              v-if="!$store.getters['store/hasPercentPlan']"
              class="mt-3"
              :answer="a"
            />
          </div>
        </div>
        <p v-if="a.answer_reason" class="help">
          {{ explanationLabel }}: {{ a.answer_reason }}
        </p>
      </div>
    </article>
  </div>
</template>

<style lang="scss" scoped>
.ctiqcomp {
  &_bad {
    color: $danger;
    border: 2px solid $danger;
  }

  &_good {
    color: $white;
    background: $success;
  }
}
</style>
